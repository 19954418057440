import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function ConsultingTapaPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'TAPA'

  const description =
    'Se la Tua Azienda produce o fornisce servizi di trasporto e logistici destinati a merci di alto valore e criminalmente appetibili, realizzare con ING GROUP un sistema di gestione conforme allo standard TAPA è un requisito imprescindibile per il successo della Tua attività, salvaguardando la sicurezza delle Tue merci, assicurando che gli ordini dei Tuoi Clienti siano portati a termine e proteggendo la reputazione e le performance finanziarie del Tuo business. I Requisiti di Sicurezza predisposti dall’Associazione, sono riconosciuti nel mondo come standard industriali da seguire per la sicurezza dei magazzini, dei centri logistici e per la sicurezza dei trasporti di merce. ING GROUP ha contribuito con la sua consulenza al raggiungimento della prestigiosa certificazione di aziende leader mondiali nel trasporto internazionale e logistica.'

  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
